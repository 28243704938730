import React from "react";
import styled from "styled-components";
import { Navbar } from "GlobalComponents";
import Typography from "@material-ui/core/Typography";

const LargeText = styled(Typography)`
  && {
    margin-top: 10vh;
    font-size: 10rem;
  }
`;

const MidSizedText = styled(Typography)`
  && {
    font-size: 3rem;
  }
`;

export const FourOFourPage: React.FC = () => (
  <>
    <Navbar />
    <LargeText>404</LargeText>
    <MidSizedText>Oops! It looks like this page doesn't exist!</MidSizedText>
  </>
);
