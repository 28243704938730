import { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface ReturnShape {
  isMobile: boolean;
}

export const useIsMobile = (): ReturnShape => {
  const [isMobile, setIsMobile] = useState(false);

  const isMobileCheck = !useMediaQuery("(min-width:600px)");

  useEffect(() => {
    setIsMobile(isMobileCheck);
  }, [isMobileCheck]);

  return { isMobile };
};
