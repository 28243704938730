import React from "react";
import {
  Navbar,
  Footer,
  InfoSquares,
  Header,
  ParagraphText,
  BackgroundImage,
  BackgroundWrapper,
  StyledLink,
} from "GlobalComponents";
import { IntroAboutText } from "text-strings";
import Container from "@material-ui/core/Container";
import { MainLivingRoom } from "image-src-strings";
import Carousel from "nuka-carousel";
import { CarouselWrapper } from "./elements";
import { useIsMobile } from "Hooks";
import { Reviews } from "text-strings";
import { CarouselCard } from "./CarouselCard";
import { Button } from "@material-ui/core";

export const About: React.FC = () => {
  const { isMobile } = useIsMobile();
  return (
    <>
      <BackgroundImage
        image={MainLivingRoom}
        alt="Background livingroom image"
      />
      <Navbar />
      <Header style={{ paddingTop: "6rem" }} h1>
        About
      </Header>
      <BackgroundWrapper style={{ marginTop: "3%" }}>
        <Container maxWidth="md">
          <ParagraphText style={{ fontSize: "1.5rem" }}>
            {IntroAboutText}
            <br />
            <br />
            <StyledLink to="/products">
              <Button variant="outlined">Products</Button>
            </StyledLink>
          </ParagraphText>
        </Container>
        <Header style={{ marginBottom: "20px" }} h2>
          Reviews:
        </Header>
        <CarouselWrapper isMobile={isMobile}>
          <Carousel
            wrapAround
            autoplay
            autoplayInterval={7000}
            cellSpacing={110}
            // cellAlign="center"
            slidesToShow={1}
            dragging={true}
            // slideWidth={0.75}
          >
            {Reviews.map(({ ...reviewData }) => (
              <CarouselCard {...reviewData} />
            ))}
          </Carousel>
        </CarouselWrapper>
      </BackgroundWrapper>
      <InfoSquares />
      <Footer />
    </>
  );
};
