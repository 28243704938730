import React from "react";
import {
  FujitsuDuctless,
  RinnaiWaterHeater,
  AmanaUnit,
  GoodmanUnit,
  TempstarUnit,
  ComfortmakerUnit,
} from "image-src-strings";
import { ProductsDataShape } from "./Products";

export const ProductsData: ProductsDataShape[] = [
  {
    title: "Rinnai Tankless Water Heaters",
    description: (
      <>
        We install and service Rinnai tankless water heaters. Learn more about
        them{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.rinnai.us/tankless-water-heater"
        >
          here
        </a>
        .
      </>
    ),
    short_description:
      "We install and service Rinnai tankless water heaters. Learn more about them here.",
    image: {
      src: RinnaiWaterHeater,
    },
  },
  {
    title: "Fujitsu Ductless Splits",
    description: (
      <>
        Halcyon systems eliminate the need for a basement or attic-located
        evaporator unit and bulky ductwork using thin copper tubing that pumps
        refrigerant directly to discreet wall mounted or concealed units inside.
        Even more remarkable, this same unit works in reverse in winter,
        absorbing heat from the outside air and moving it indoors to heat your
        home. The result? Efficient cooling and heating for year-round,
        whole-house comfort in most climates.
      </>
    ),
    short_description: `Halcyon systems eliminate the need for a basement or attic-located evaporator unit and bulky ductwork by using thin copper tubing that pumps refrigerant directly to discreet wall mounted or concealed units inside.`,
    image: {
      src: FujitsuDuctless,
      width_percentage: 52,
    },
  },
  {
    title: "Amana",
    description: (
      <>
        Check out the Amana lineup{" "}
        <a
          href="http://www.amana-hac.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </>
    ),
    short_description: "Check out the Amana lineup here",
    image: { src: AmanaUnit, width_percentage: 85 },
  },
  {
    title: "Goodman",
    description: (
      <>
        We work with Goodman products. Check out their inventory and features{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.goodmanmfg.com/"
        >
          here
        </a>
        .
      </>
    ),
    short_description:
      "We work with Goodman products. Check out their inventory and features here",
    image: { src: GoodmanUnit },
  },
  {
    title: "Tempstar",
    description: (
      <>
        We work with all Tempstar products. Check out what they have to offer{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.tempstar.com/"
        >
          here
        </a>
        .
      </>
    ),
    short_description:
      "We work with all Tempstar products. Check out what they have to offer here.",
    image: {
      src: TempstarUnit,
      non_mobile_width_percentage: 38,
      width_percentage: 76,
    },
  },
  {
    title: "Comfortmaker",
    description: (
      <>
        We work with all Comfortmaker products! Here is some information about
        Comfortmaker from their website:
        <br />
        <br />
        Comfortmaker® SoftSound® SXT+ Two-Stage Heat Pumps featuring the
        Observer™ communicating system are designed to take your home to a new
        level of comfort and efficiency. Teamed with our Observer communicating
        wall control and a Comfortmaker communicating fan coil or gas furnace,
        the SoftSound SXT+ functions as part of a complete communicating home
        comfort system. Various components of the system “talk” to each other
        electronically, fine-tuning comfort levels while maximizing energy
        savings.
        <br />
        <br />
        Learn more at the Comfortmaker website –{" "}
        <a
          href="http://comfortmaker.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          comfortmaker.com
        </a>
        <br />
        <br />
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.comfortmaker.com/c-energy.html"
        >
          Click here
        </a>{" "}
        to check out the energy savings on Comfortmaker products!
      </>
    ),
    short_description: "We work with all Comfortmaker products!",
    image: {
      src: ComfortmakerUnit,
      non_mobile_width_percentage: 40,
      width_percentage: 76,
    },
  },
];
