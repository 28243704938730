import React from "react";
import {
  Header,
  Image,
  ParagraphText,
  InfoSquares,
  BackgroundWrapper,
  Navbar,
  Footer,
  BackgroundImage,
} from "GlobalComponents";
import { MainLivingRoom, Fireplace } from "image-src-strings";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { MainPromoText } from "text-strings";
import { useIsMobile } from "Hooks";
import Button from "@material-ui/core/Button";

export const Homepage: React.FC = () => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <BackgroundImage
        image={MainLivingRoom}
        alt="Background livingroom image"
      />
      <Navbar />
      <Header
        style={{ paddingTop: isMobile ? "6rem" : "11rem" }}
        h1={!isMobile}
        h2={isMobile}
      >
        Johnson Heating & Cooling
      </Header>
      <BackgroundWrapper
        style={{
          marginTop: "5%",
          width: "50%",
          borderRadius: "10px",
          minWidth: "300px",
        }}
      >
        <Header h2>
          Call to schedule your service or install
          <a
            style={{
              color: "black",
              textDecoration: "none",
              marginLeft: isMobile ? "0px" : "44px",
            }}
            href="tel:5702291296"
          >
            <Button variant="outlined">Click to call: (570)229-1296</Button>
          </a>
        </Header>
      </BackgroundWrapper>
      <BackgroundWrapper style={{ marginTop: "5%" }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                textAlign: isMobile ? "center" : "initial",
                paddingLeft: isMobile ? "0px" : "50px",
              }}
            >
              <Header h2>Experienced HVAC Technician</Header>
              <ParagraphText
                style={{ lineHeight: "34px", letterSpacing: "0.5px" }}
              >
                {MainPromoText}
              </ParagraphText>
            </Grid>
            <Grid
              style={{
                textAlign: "center",
                margin: "auto",
              }}
            >
              <Image
                src={Fireplace}
                width={isMobile ? "300px" : "400px"}
                alt="Fireplace picture"
              />
            </Grid>
          </Grid>
        </Container>
      </BackgroundWrapper>
      <InfoSquares />
      <Footer />
    </>
  );
};
