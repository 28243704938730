import React, { useState } from "react";
import {
  BackgroundImage,
  Navbar,
  Header,
  InfoSquares,
  Footer,
  BackgroundWrapper,
  Modal,
  ParagraphText,
} from "GlobalComponents";
import { MainLivingRoom } from "image-src-strings";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { StyledCard, StyledCardMedia } from "./elements";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useIsMobile } from "Hooks";
import { ProductsData } from "./ProductData";

export interface ProductsDataShape {
  title: string;
  description: React.ReactElement;
  image: {
    src: string;
    width_percentage?: number;
    non_mobile_width_percentage?: number;
  };
  short_description: string;
}

export const Products: React.FC = () => {
  const [productModalIsOpen, setProductModalIsOpen] = useState(false);
  const [modalProductData, setModalProductData] = useState<
    Omit<ProductsDataShape, "short_description">
  >({
    title: "",
    description: <></>,
    image: { src: "", width_percentage: 32 },
  });
  const { isMobile } = useIsMobile();

  return (
    <>
      <Modal
        show={productModalIsOpen}
        setShow={setProductModalIsOpen}
        content={
          <>
            <img
              src={modalProductData.image.src}
              style={{
                maxHeight: "300px",
                width: isMobile
                  ? `${modalProductData.image.width_percentage || 0 + 15}%`
                  : "auto",
              }}
              alt="_"
            />
            <Header h2>{modalProductData.title}</Header>
            <ParagraphText>{modalProductData.description}</ParagraphText>
          </>
        }
      />
      <BackgroundImage
        image={MainLivingRoom}
        alt="Background livingroom image"
      />
      <Navbar />
      <Header style={{ paddingTop: "6rem" }} h1>
        Products
      </Header>
      <BackgroundWrapper style={{ marginTop: "3%" }}>
        <Container maxWidth="lg">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {ProductsData.map(
              ({
                title,
                short_description,
                description,
                image: {
                  src,
                  width_percentage = 32,
                  non_mobile_width_percentage,
                },
              }) => (
                <div key={title}>
                  <StyledCard
                    onClick={() => {
                      setModalProductData({
                        title,
                        description,
                        image: { src, width_percentage },
                      });
                      setProductModalIsOpen(true);
                    }}
                  >
                    <CardActionArea>
                      <div style={{ height: "140px" }}>
                        <StyledCardMedia
                          image={src}
                          non_mobile_width_percentage={
                            non_mobile_width_percentage
                          }
                          width_percentage={width_percentage}
                        />
                      </div>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {short_description.length > 80
                            ? `${short_description.slice(0, 80)}...`
                            : short_description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </StyledCard>
                </div>
              )
            )}
          </div>
        </Container>
      </BackgroundWrapper>
      <InfoSquares />
      <Footer />
    </>
  );
};
