import React, { useState } from "react";
import { StyledLink } from "GlobalComponents";
import { NavButton, NavMenuButton, MobileNavButton } from "./elements";
import { useIsMobile } from "Hooks";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Zoom from "@material-ui/core/Zoom";

export const Navbar: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { isMobile } = useIsMobile();

  const paddingVals = isMobile ? "0px" : "16px";

  return (
    <AppBar
      style={{ backgroundColor: "#e4e4e4" }}
      title="Johnson Heating & Cooling"
    >
      <Toolbar style={{ paddingRight: paddingVals, paddingLeft: paddingVals }}>
        <StyledLink to="/">
          <Button>
            <HomeIcon
              style={{
                fontSize: "1.6rem",
                marginRight: "4px",
                marginBottom: "3px",
              }}
            />
            Johnson Heating & Cooling
          </Button>
        </StyledLink>
        {isMobile ? (
          <>
            <NavMenuButton onClick={() => setMenuIsOpen(!menuIsOpen)}>
              <CloseIcon
                style={{
                  position: "absolute",
                  fontSize: menuIsOpen ? "25px" : "0px",
                  top: -5,
                  right: -1,
                }}
              />
              <MenuIcon style={{ fontSize: !menuIsOpen ? "25px" : "0px" }} />
            </NavMenuButton>
          </>
        ) : null}
        {!isMobile ? (
          <>
            <StyledLink style={{ marginLeft: "20px" }} to="/about">
              <NavButton>About</NavButton>
            </StyledLink>
            <StyledLink to="/products">
              <NavButton>Products</NavButton>
            </StyledLink>
            <a href="tel:5702291296" style={{ textDecoration: "none" }}>
              <NavButton>Call us! (570) 229-1296</NavButton>
            </a>
          </>
        ) : null}
      </Toolbar>
      <Zoom
        style={{
          display: isMobile && menuIsOpen ? "inline-block" : "hidden",
        }}
        timeout={200}
        appear={false}
        in={menuIsOpen}
      >
        <div>
          {isMobile && menuIsOpen ? (
            <div
              style={{
                textAlign: "center",
                margin: "0 25px 10px",
              }}
            >
              <StyledLink style={{ width: "90%" }} to="/about">
                <MobileNavButton variant="outlined">About</MobileNavButton>
              </StyledLink>
              <StyledLink style={{ width: "90%" }} to="/products">
                <MobileNavButton variant="outlined">Products</MobileNavButton>
              </StyledLink>
              <a href="tel:5702291296" style={{ textDecoration: "none" }}>
                <MobileNavButton variant="outlined">
                  Call us! (570) 229-1296
                </MobileNavButton>
              </a>
            </div>
          ) : null}
        </div>
      </Zoom>
    </AppBar>
  );
};
