import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FooterContainer } from "./elements";
import Container from "@material-ui/core/Container";
import { useIsMobile } from "Hooks";

export const Footer: React.FC = () => {
  const { isMobile } = useIsMobile();

  return (
    <FooterContainer>
      <Container maxWidth="md">
        <FacebookIcon style={{ fontSize: "50px", color: "blue" }} />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "1.5rem",
            fontFamily: "Dosis, sans-serif",
          }}
        >
          Johnson HVAC | Honesdale, PA{" "}
          {isMobile ? (
            <>
              <br />
              <a href="tel:5702291296">570-229-1296</a>
            </>
          ) : (
            <>
              | <a href="tel:5702291296">570-229-1296</a>
            </>
          )}
          <div
            style={{
              fontSize: "0.9rem",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Website developed by Bruce Johnson{" "}
            <a href="tel:5702292613">(570-229-2613)</a>
          </div>
        </div>
      </Container>
    </FooterContainer>
  );
};
