import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { SemiTransparentGrey } from "colors";

interface HeaderProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
}

export const Header = styled(Typography)<HeaderProps>`
  && {
    font-family: "Dosis", sans-serif;
    ${({ h1 }) => h1 && "font-size: 3.5rem"}
    ${({ h2 }) => h2 && "font-size: 2rem"}
  }
`;

export const StyledImg = styled.img<{ isHidden: boolean }>`
  ${({ isHidden }) => isHidden && "display: hidden;"}
`;

export const BackGroundImageStyles = styled.div`
  && {
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: -90;
    height: 100%;
  }
`;

export const ParagraphText = styled.p`
  && {
    font-family: "Dosis", sans-serif;
    font-size: 1.2rem;
    white-space: break-spaces;
  }
`;

export const BackgroundWrapper = styled.div<{ bgColor?: string }>`
  && {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
    height: fit-content;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const NavButton = styled(Button)`
  && {
    font-weight: bold;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  background-color: ${SemiTransparentGrey};
  min-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: auto;
`;

export const ModalContentWrapper = styled.div<{ isMobile?: boolean }>`
  text-align: center;
  background-color: white;
  height: fit-content;
  margin: auto;
  border-radius: 6px;
  overflow: auto;
  width: 80%;
  ${({ isMobile }) => !isMobile && "width: 40%;"}
  margin-top: 6%;
  max-height: 580px;
`;

export const ReadMoreText = styled.div`
  display: inline;
  font-family: monospace;
  cursor: pointer;
  color: blue;
`;

export const NavMenuButton = styled.div`
  &&& {
    right: 4vw;
    padding: 0px;
    position: absolute;
    color: black;
  }
`;

export const MobileNavButton = styled(Button)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
`;
