import React from "react";
import {
  CarouselCardWrapper,
  CarouselCardTitle,
  CarouselCardReview,
  CarouselCardName,
} from "./elements";

interface Props {
  title: string;
  review: string;
  name: string;
}

export const CarouselCard: React.FC<Props> = ({ title, review, name }) => (
  <CarouselCardWrapper>
    <CarouselCardTitle>
      {title} <br />
      <CarouselCardName>{name}</CarouselCardName>
    </CarouselCardTitle>
    <CarouselCardReview>{review}</CarouselCardReview>
  </CarouselCardWrapper>
);
