import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Header, ParagraphText, BackgroundWrapper } from "GlobalComponents";
import { FujitsuDescription, Reviews, EfficiencyTips } from "text-strings";
import { SemiTransparentWhite } from "colors";
import { truncate } from "utilities";
import Button from "@material-ui/core/Button";
import { ReadMoreText, StyledLink } from "../elements";
import { useIsMobile } from "Hooks";
import { InfoSquaresModals } from "./InfoSquaresModals";
export interface ReviewData {
  name: string;
  title: string;
  review: string;
}

export interface TipData {
  title: string;
  tip: string;
}

export const InfoSquares: React.FC = () => {
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [showFujitsuModal, setShowFujitsuModal] = useState(false);
  const [reviewData] = useState<ReviewData>(
    Reviews[Math.floor(Math.random() * Reviews.length)]
  );
  const [tipData] = useState<TipData>(
    EfficiencyTips[Math.floor(Math.random() * EfficiencyTips.length)]
  );

  const { isMobile } = useIsMobile();

  return (
    <>
      <InfoSquaresModals
        showFujitsuModal={showFujitsuModal}
        showReviewsModal={showReviewsModal}
        showTipsModal={showTipsModal}
        setShowFujitsuModal={setShowFujitsuModal}
        setShowReviewsModal={setShowReviewsModal}
        setShowTipsModal={setShowTipsModal}
        reviewData={reviewData}
        tipData={tipData}
      />
      <BackgroundWrapper
        style={{
          marginTop: "5%",
          backgroundColor: SemiTransparentWhite,
          width: isMobile ? "90%" : "60%",
          marginBottom: "5%",
          borderRadius: "8px",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Header h2>Fujitsu</Header>
              <ParagraphText>{truncate(FujitsuDescription, 160)}</ParagraphText>
              <Button
                onClick={() => setShowFujitsuModal(true)}
                variant="outlined"
              >
                Read More
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Header h2>Efficiency Tips</Header>
              <ParagraphText>{truncate(tipData.tip, 160)}</ParagraphText>
              <Button variant="outlined" onClick={() => setShowTipsModal(true)}>
                Read More
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Header h2>Reviews</Header>
              <ParagraphText>{truncate(reviewData.review, 160)}</ParagraphText>
              <ReadMoreText
                style={{
                  display: "block",
                  marginBottom: "10px",
                  top: "-15px",
                  position: "relative",
                  fontSize: "1.2rem",
                }}
                onClick={() => setShowReviewsModal(true)}
              >
                (Read more)
              </ReadMoreText>
              <StyledLink to="/about">
                <Button variant="outlined">See More</Button>
              </StyledLink>
            </Grid>
          </Grid>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
