import React, { useState } from "react";
import { BackGroundImageStyles } from "./elements";
import { Image } from "./Image";
import { useIsMobile } from "Hooks";
import { VeryLightGreen } from "colors";

interface Props {
  image: string;
  alt: string;
}

export const BackgroundImage: React.FC<Props> = ({ image, alt }) => {
  const [bgImageHasLoaded, setBgImageHasLoaded] = useState(false);
  const { isMobile } = useIsMobile();
  return (
    <>
      {/* Dark grey overlay (helps things stand out against the bg image) */}
      {bgImageHasLoaded ? (
        <BackGroundImageStyles
          style={{ backgroundColor: "#6b6b6b40", zIndex: -80 }}
        />
      ) : null}
      {isMobile ? (
        <BackGroundImageStyles
          style={{ backgroundColor: VeryLightGreen, zIndex: -80 }}
        />
      ) : null}
      <BackGroundImageStyles>
        <Image
          showLoading={false}
          alt={alt}
          src={image}
          width="100%"
          height="100%"
          onChangeLoad={({ imageHasLoaded }) =>
            setBgImageHasLoaded(imageHasLoaded)
          }
        />
      </BackGroundImageStyles>
    </>
  );
};
