export const MainLivingRoom =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/living-room.jpg";
export const Fireplace =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/fireplace.jpg";
export const RinnaiWaterHeater =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/rinnai-tankless-water-heater.png";
export const FujitsuDuctless =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/fujitsu-image.png";
export const AmanaUnit =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/amana-unit.png";
export const GoodmanUnit =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/goodman-unit.png";
export const TempstarUnit =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/tempstar-unit.png";
export const ComfortmakerUnit =
  "https://johnson-heating-and-cooling.s3.amazonaws.com/comfortmaker-unit.png";
