export const FujitsuDescription =
  "What is a mini split? Halcyon systems eliminate the need for a basement or attic-located evaporator unit and bulky ductwork by using thin copper tubing that pumps refrigerant directly to discreet wall mounted or concealed units inside. Even more remarkable, this same unit works in reverse in winter, absorbing heat from the outside air and moving it indoors to heat your home. The result? Efficient cooling and heating for year-round, whole house comfort in most climates.";

export const FujitsuDescriptionInfo = {
  title: "What is a mini split?",
  tip: `Halcyon systems eliminate the need for a basement or attic-located evaporator unit and bulky ductwork by using thin copper tubing that pumps refrigerant directly to discreet wall mounted or concealed units inside. Even more remarkable, this same unit works in reverse in winter, absorbing heat from the outside air and moving it indoors to heat your home. The result? Efficient cooling and heating for year-round, whole house comfort in most climates.`,
};

export const Reviews = [
  {
    title: "Central AC Repair for Lynn",
    review:
      "Frank returned calls promptly and showed up for service in a timely and professional manner. Was honest and thorough with his work. Explained problem to me and got my ac running during our worst heat wave.",
    name: "Lynn G.",
  },
  {
    title: "Review of Heating System Repair",
    review:
      "Frank was amazing, he came right over and the job was done incredibly well, he also cleaned the filter on a pump in my basement and didn’t charge me. Bottom line: I think you’ll be sorry if you didn’t hire him, he is my plumber of choice from now on.",
    name: "Michael W.",
  },
  {
    title: "Heating System Repair",
    review:
      "He contacted us immediately even though it was a holiday weekend.We found he was very professional and friendly and went out of his way to get the parts necessary to complete the repairs.",
    name: "Faith O.",
  },
];

export const EfficiencyTips = [
  {
    title: `How long should an air conditioner last?`,
    tip: `How long should an air conditioner last?

    “If you get 20 years out of a home air conditioner, you’re doing well.” The gist of that statement is that a lot of air conditioners will not last that long.
    The good news is that homeowners can do something about it. There are things people can do to extend the life of the ac. There are a few of fundamental tips to help homeowners get the most out of an air conditioner. The primary tip is maintenance.
    For some homeowners, the only contact they have with their air conditioner is when they turn the switch from “Heat” to “AC” after the passing of winter. Without regular maintenance people will be lucky to get 10 years out of an air conditioner.
    Having your ac inspected by a HVAC professional once a year. The most likely time to inspect air conditioners is in the spring, before an ac unit is called upon to cool the home.

    A professional will make sure that the compressor is operating correctly and will make sure that the unit is properly charged. If either of these factors are out of whack, the life of an air conditioner can be seriously shortened.

    The compressor is inside the part of the air conditioner that is outside the home. If the coils are clogged, the ac unit will have to work much harder to do the job of cooling a home. Not only will this make the air conditioner more expensive to operate, it will also shorten the life of the unit.
    When an HVAC professional comes out to a home to service an air conditioner, one part of that service is to clean the coils surrounding the compressor. Barrett said that cottonwood is the typical bane of air conditioners. That white fibrous material creates a mat over the coils and can virtually choke an ac unit.
    While cleaning the coils is part of the process when calling in a technician, it’s also something the homeowner can do him or herself by using water and a hose. Don’t spray into the coil. Instead, spray down at a 45-degree angle to clear the debris away.

    Another question is what to do with the compressor during the off season. Actually, the units are made for the outdoors. It is not necessary to cover a compressor. Winter, generally, is not a problem for an air conditioner. Just, as was suggested above, make sure it is inspected and serviced at the start of each cooling season.`,
  },
  {
    title: "How Often Should I Service HVAC?",
    tip: `How often should you have your HVAC Serviced?

    Your HVAC system should be checked out at least once a year by a qualified technician. The best way to do this is to have your heating and cooling units checked separately. This may cost more money upfront since you will be paying for two service calls a year; however, this is often the best method to have your units checked.
    Many people love to have both heating and cooling units checked at the same time. For instance, in the spring before hot weather sets in, many people will have air-conditioning units serviced to have it ready for the heat. While the technician is there, they may also have them service the furnace. However, since the furnace will not be operated again for several months, various problems could arise while it sits idle throughout the summer.

    For this reason, it is wise to have the air-conditioning unit checked in the Spring, and the heating system checked in the early Fall. This will help have both systems ready to go when the temperature requires. Nothing is worse than waiting until the last minute when a system is needed, switching it on, and having nothing happen. Unfortunately, this is what many people do, and then the HVAC contractors are swamped with calls for repairs. During an extremely hot or cold spell, you may have to wait several days to have your HVAC system repaired.

    So, remember how often should your HVAC system be checked? At least once a year. For optimal service make sure to service the heating and cooling units separately a couple of months before they will be required.`,
  },
  {
    title: "Get Rid of AC Odor",
    tip: `How to Get Rid of Air Conditioner Odor

    Air conditioners are devices designated to lower the temperature of an area. Most buildings and houses today have air-conditioning units, given the comfort that they provide, especially during summer. The only problem is sometimes their emissions contain not only cool air but also a strong, pungent scent that can spread quickly, even inside large rooms. If you think that’s bad, imagine how agonizing it is for car owners, with their limited space, to bear bad air conditioner odor.
    
    Causes of Air Conditioner Odor

    Many things cause air conditioner odor. It can be a strong odor picked up from inside or outside the room. It can also be a sign that the unit requires some cleaning and maintenance. To give you a clearer picture, here are the causes of bad air conditioner odor:

    Clogged Air-Conditioning Drain – Long-running air conditioner odors are caused by a clogged water drain inside the unit. If the air conditioner has not been cleaned for a long time, dirt accumulates and blocks the drainage. As a result, the water is stuck in the condensation chamber, which eventually stagnates and produces a foul odor. That odor then escapes through the emission vent and proceeds to infest your room.

    Smoke – Smoke, being a dense gas, can stay inside the chambers of an air-conditioner. Once the unit is turned on, it exits through the emission vent, bringing its strong heavy smell in your car or room. The odor usually lasts for about one to five minutes, depending on if smoke is still present inside the unit’s vents and chambers.

    Dead Animal – Small mice have a habit of touring the crevices behind the walls and beyond the ceiling. They creep inside cracks and sometimes end up inside the pipes that lead to your air conditioner's chambers. When they perish inside the condensation chamber, you can probably imagine how bad the air conditioner’s odor will be.
    
    Miscellaneous Odors – Like smoke, other strong odors like your neighbor’s cooking, insecticides, or the nasty stench coming from garbage trucks can stay inside the air conditioner. They will unceremoniously exit the device upon opening. Good thing the stench fades after a minute or so, except for the smell of insecticides, which stay much longer.

    Accumulated dirt from the Air Filter – The air filter, in exchange for sieving air, collects dust and other small particles near the air conditioner. When the filter accumulates too much dirt and dust, it can produce a faintly pungent odor, which gradually fades. Your real concern is that the odors, coming from the other causes, take longer to fade than with a clogged filter.`,
  },
];

export const MainPromoText = `Johnson Heating and Cooling is a family owned and operated business with over 20 years experience, specializing in the design, installation, service and repair of heating and cooling systems for residential clients. We are a full service HVAC company, up-to-date on the latest technology and advances in high efficient heating and air conditioning. We service all major brands of air conditioning, furnaces, boilers, humidifiers, air handlers, and thermostats.

You deserve to have the latest high efficient units installed the right way the first time. Call today to have your yearly cleaning and unit check, or unit installation performed by a fully trained professional.`;

export const IntroAboutText = `Frank Johnson is not only skilled in the installation of several HVAC products, but he is also certified to perform maintenance on several products.`;
