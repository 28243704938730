import Card from "@material-ui/core/Card";
import styled from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";

export const StyledCard = styled(Card)`
  && {
    min-width: 345px;
    max-width: 345px;
    display: flex;
    margin: 10px;
    height: 300px;
  }
`;

export const StyledCardMedia = styled(CardMedia)<{
  width_percentage: number;
  non_mobile_width_percentage?: number;
}>`
  && {
    height: 100%;
    width: ${({ width_percentage, non_mobile_width_percentage }) => {
      if (width_percentage && !non_mobile_width_percentage) {
        return width_percentage;
      } else if (non_mobile_width_percentage) {
        return non_mobile_width_percentage;
      }
    }}%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
