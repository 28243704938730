import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { StyledImg } from "./elements";
import { VeryLightGreen } from "colors";

interface OnChangeLoadArgs {
  imageHasLoaded: boolean;
}

interface Props {
  src: string;
  height?: string;
  width?: string;
  alt?: string;
  showLoading?: boolean;
  onChangeLoad?(arg0: OnChangeLoadArgs): void;
}

export const Image: React.FC<Props> = ({
  onChangeLoad = () => null,
  showLoading = true,
  ...props
}) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  useEffect(() => {
    onChangeLoad({ imageHasLoaded });
  }, [imageHasLoaded, onChangeLoad]);

  return (
    <>
      {!imageHasLoaded && showLoading && (
        <Skeleton
          style={{
            textAlign: "inherit",
            margin: "inherit",
            borderRadius: "8px",
          }}
          variant="rect"
          width={props.width || 130}
          height={props.height || 118}
        />
      )}
      {!imageHasLoaded && !showLoading && (
        <div
          style={{
            backgroundColor: VeryLightGreen,
            width: props.width || 130,
            height: props.height || 118,
          }}
        />
      )}
      <StyledImg
        style={{ borderRadius: "8px" }}
        isHidden={imageHasLoaded}
        onLoad={() => setImageHasLoaded(true)}
        {...props}
        alt={props.alt || "Alt text"}
      />
    </>
  );
};
