import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Homepage } from "Homepage";
import { About } from "About";
import { Products } from "Products";
import { FourOFourPage } from "FourOFourPage";
import ScrollToTop from "react-router-scroll-top";

function App() {
  return (
    <div style={{ textAlign: "center" }}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact render={() => <Homepage />} />
            <Route path="/about" render={() => <About />} />
            <Route path="/products" render={() => <Products />} />
            <Route render={() => <FourOFourPage />} />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
