import React from "react";
import { FujitsuDescriptionInfo } from "text-strings";
import { Modal, Header, ParagraphText } from "GlobalComponents";
import { ReviewData, TipData } from "./InfoSquares";
import Button from "@material-ui/core/Button";

interface Props {
  showReviewsModal: boolean;
  showTipsModal: boolean;
  showFujitsuModal: boolean;
  setShowReviewsModal: React.Dispatch<boolean>;
  setShowFujitsuModal: React.Dispatch<boolean>;
  setShowTipsModal: React.Dispatch<boolean>;
  reviewData: ReviewData;
  tipData: TipData;
}

export const InfoSquaresModals: React.FC<Props> = ({
  showFujitsuModal,
  showReviewsModal,
  showTipsModal,
  setShowFujitsuModal,
  setShowReviewsModal,
  setShowTipsModal,
  reviewData,
  tipData,
}) => (
  <>
    <Modal
      show={showReviewsModal}
      setShow={setShowReviewsModal}
      content={
        <>
          <Header h2>{reviewData.title}</Header>
          <ParagraphText>
            {reviewData.review}
            <div
              style={{
                marginTop: "22px",
                fontSize: "1.6rem",
                fontFamily: "monospace",
              }}
            >
              - {reviewData.name}
            </div>
          </ParagraphText>
        </>
      }
    />
    <Modal
      show={showTipsModal}
      setShow={setShowTipsModal}
      content={
        <>
          <Header h2>{tipData.title}</Header>
          <ParagraphText>{tipData.tip}</ParagraphText>
        </>
      }
    />
    <Modal
      show={showFujitsuModal}
      setShow={setShowFujitsuModal}
      content={
        <>
          <Header h2>{FujitsuDescriptionInfo.title}</Header>
          <ParagraphText>{FujitsuDescriptionInfo.tip}</ParagraphText>
          <a
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
            href="https://www.fujitsugeneral.com/us/resources/pdf/support/downloads/halcyon-2020-full-line-brochure-01.pdf"
          >
            <Button style={{ marginBottom: "25px" }} variant="outlined">
              View Documents
            </Button>
          </a>
        </>
      }
    />
  </>
);
