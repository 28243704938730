import styled from "styled-components";
import {
  SemiTransparentGrey,
  DarkerGrey,
  SemiTransparentWhite,
  DarkerBlue,
} from "colors";
import { Typography } from "@material-ui/core";

export const CarouselWrapper = styled.div<{ isMobile: boolean }>`
  & {
    text-align: center;
    margin: auto;
    ${({ isMobile }) => !isMobile && "width: 50%;"};
  }
  margin-top: 110px;
  .slider-control-bottomcenter ul {
    background-color: ${SemiTransparentGrey};
    height: 23px;
    border-radius: 10px;
  }
  .slider-control-centerright button {
    border-radius: 5px;
  }
  .slider-control-centerleft button {
    border-radius: 5px;
  }
`;

export const CarouselCardWrapper = styled.div`
  width: 100%;
  height: 300px;
  max-height: 300px;
  background-color: ${DarkerGrey};
  border-radius: 8px;
  overflow: overlay;
`;

export const CarouselCardTitle = styled(Typography)`
  && {
    padding-top: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 1.6rem;
    color: ${DarkerBlue};
  }
`;

export const CarouselCardReview = styled(Typography)`
  && {
    font-size: 1.3rem;
    color: ${SemiTransparentWhite};
    padding: 10px 60px 40px;
  }
`;

export const CarouselCardName = styled(Typography)`
  && {
    font-size: 1.2rem;
    padding-top: 15px;
    color: black;
    display: inline;
    font-weight: bold;
    letter-spacing: 2px;
  }
`;
