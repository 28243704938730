import React from "react";
import { useIsMobile } from "Hooks";
import Container from "@material-ui/core/Container";
import { ModalContentWrapper } from "GlobalComponents";
import Fab from "@material-ui/core/Fab";
import Close from "@material-ui/icons/Close";
import _Modal from "@material-ui/core/Modal";

interface ModalProps {
  show: boolean;
  setShow: React.Dispatch<boolean>;
  content: React.ReactElement;
}

export const Modal: React.FC<ModalProps> = ({ show, setShow, content }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_Modal onClose={() => setShow(false)} open={show}>
        <ModalContentWrapper isMobile={isMobile}>
          <Container style={{ paddingTop: "30px" }} maxWidth="md">
            {content}
          </Container>
          <Fab
            onClick={() => setShow(false)}
            size="small"
            style={{ top: "-12px" }}
          >
            <Close />
          </Fab>
        </ModalContentWrapper>
      </_Modal>
    </>
  );
};
